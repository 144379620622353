import { takeLatest, call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import browserHistory from 'components/root/browserHistory';
import * as analyticsActions from 'redux/modules/Analytics/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { featureFlagsRequest } from 'app/redux/modules/FeatureFlags/actions';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { STORE_NAME as ServiceSelection } from 'redux/modules/ServiceSelection/constants';
import { STORE_NAME as Application } from 'redux/modules/App/constants';

import {
  loginFailure,
  loginSuccess,
  logout,
  getProfileSuccess,
  supportingIdLoginSuccess,
  supportingIdLoginFailure,
  setHostedId,
  ssoVerifySuccess,
  ssoVerifyFailure,
  getLeadSuccess,
  getLeadFailure,
  updateReferralField,
  updateField,
  validateForm,
  setSupportingIdDialog,
  getStartedPartner,
  setDisplayName,
  setEnergyOnlyOrganic,
} from './actions';
import {
  applicationGetStartedPartner,
  applicationGetStarted,
  applicationGetStartedRemote,
  createApplicationPartnerRemoteSupport,
  setPageParams,
} from '../App/actions';
import {
  POST_PARTNER_LOGIN_REQUEST,
  JTC_GET_STARTED,
  PARTNER_LOGOUT,
  POST_SUPPORTING_PARTNER_LOGIN_REQUEST,
  POST_SUPPORTING_PARTNER_LOGIN_SUCCESS,
  ERROR_NOT_QUALIFIED,
  SSO_VERIFY_REQUEST,
  PROXY_LOGIN,
  GET_LEAD_REQUEST,
  CUSTOMER_RELATIONSHIP_REFERRAL,
} from './constants';
import postPartnerLogin from './service/postPartnerLogin';
import getPartnerProfile from './service/getPartnerProfile';
import isPartnerQualifiedToSupport from './service/isPartnerQualifiedToSupport';
import postSSOSignIn from './service/postSSOSignIn';
import getLead from './service/getLead';
import { PartnerLoginApi } from './api';
import { LandingPageApi } from 'redux/modules/LandingPage/api';
import { RestoreApi } from 'redux/modules/Restore/api';
import * as restoreActions from 'redux/modules/Restore/actions';
import * as landingPageTypes from 'redux/modules/LandingPage/types';
import { SUPPORT_SELECTION_OPTIONS } from 'redux/modules/LandingPage/constants';

const errorMessages = {
  invalid_credentials:
    'Invalid partner ID / password combination, please try again.',
};

export function* handleJTCGetStarted(action) {
  if (yield select(RestoreApi.shouldInitiateRestore)) {
    yield put(restoreActions.initRestore(action));
    return;
  }

  const [
    partnerId,
    partnerToken,
    customerRelationship,
    referrer,
    supportingPartnerId,
    hostedId,
    supportingPartnerToken,
    lead,
    selectedSupport,
  ] = yield select((state) => [
    PartnerLoginApi.getPartnerId(state),
    PartnerLoginApi.getPartnerToken(state),
    PartnerLoginApi.getPartnerRelationship(state),
    PartnerLoginApi.getPartnerRelationshipDetails(state),
    PartnerLoginApi.getSupportingId(state),
    PartnerLoginApi.getHostedId(state),
    PartnerLoginApi.getSupportingIdToken(state),
    PartnerLoginApi.getLead(state),
    LandingPageApi.getSupportSelection(state),
  ]);

  if (action.partnerLogin) {
    if (selectedSupport === SUPPORT_SELECTION_OPTIONS.remote) {
      yield put(
        createApplicationPartnerRemoteSupport(
          partnerId,
          partnerToken,
          customerRelationship,
          referrer,
          action.hostedId || hostedId,
          lead
        )
      );
    } else if (
      selectedSupport === SUPPORT_SELECTION_OPTIONS.present &&
      !supportingPartnerToken
    ) {
      yield put(setSupportingIdDialog(true));
    } else {
      yield put(
        applicationGetStartedPartner(
          partnerId,
          partnerToken,
          customerRelationship,
          referrer,
          supportingPartnerId,
          supportingPartnerToken,
          action.hostedId || hostedId,
          lead
        )
      );
    }
  } else if (action.remotePartnerId) {
    yield put(
      applicationGetStartedRemote(
        action.remotePartnerId,
        customerRelationship,
        referrer,
        supportingPartnerId,
        lead
      )
    );
  } else {
    yield put(
      logout([
        [ServiceSelection, 'preselect'],
        [Application, 'exclusionParameters'],
      ])
    );
    yield put(applicationGetStarted(action.hostedId, action.leadCode));

    // save lead code/hosted id for later analytics use
    // since starting an organic app forces logging out
    // and clearing state
    yield put(
      setPageParams({
        hosted_id: action.hostedId,
        lead_code: action.leadCode || lead?.code,
        lead_id: lead?.id,
        ...action.marketingParams,
      })
    );
  }

  yield put(setEnergyOnlyOrganic(action.energyOnly));
}

export function* handlePartnerLogin(action) {
  try {
    const {
      data: { access_token, refresh_token, expires_in, token_type },
    } = yield call(postPartnerLogin, action.partnerId, action.password);
    const token = PartnerLoginApi.createToken(token_type, access_token);
    const profileResponse = yield call(getPartnerProfile, token);

    const { partnerId, positionId, email } = profileResponse.data;

    yield put(getProfileSuccess(profileResponse.data));
    yield put(
      loginSuccess(
        positionId,
        access_token,
        refresh_token,
        expires_in,
        token_type
      )
    );

    yield put(
      analyticsActions.trackIdent(
        partnerId,
        {
          email,
        },
        false
      )
    );

    yield put(featureFlagsRequest(token));
    yield call(handleURL);
  } catch (error) {
    if (error.response && error.response.status < 500) {
      const errorMsg = errorMessages[error.response.data.error];
      if (errorMsg) {
        yield put(loginFailure(errorMsg));
        return;
      }
    } else {
      Sentry.log(error, ERRORS.PARTNER_LOGIN);
    }

    yield put(
      openErrorGlobalDialog('We encountered a system error logging you in')
    );
    yield put(loginFailure('System error'));
  }
}

function* handleSupportingPartnerLogin(action) {
  try {
    const {
      data: { access_token, token_type },
    } = yield call(
      postPartnerLogin,
      action.supportingPartnerId,
      action.password
    );
    const token = PartnerLoginApi.createToken(token_type, access_token);

    const {
      data: { isQualified },
    } = yield call(isPartnerQualifiedToSupport, token, action.partnerToSupport);

    const supportingProfileResponse = yield call(getPartnerProfile, token);
    const { id: supportingId } = supportingProfileResponse.data;

    if (!isQualified) {
      yield put(supportingIdLoginFailure(ERROR_NOT_QUALIFIED));
      return;
    }

    yield put(supportingIdLoginSuccess(supportingId, access_token, token_type));
  } catch (error) {
    if (error.response && error.response.status < 500) {
      const errorMsg = errorMessages[error.response.data.error];

      if (errorMsg) {
        yield put(supportingIdLoginFailure(errorMsg));
        return;
      }
    } else {
      Sentry.log(error, ERRORS.SUPPORTING_PARTNER_LOGIN);
    }

    yield put(
      openErrorGlobalDialog('We encountered a system error logging you in')
    );
    yield put(supportingIdLoginFailure('System error'));
  }
}

export function* handlePartnerLogout() {
  // Always re-direct to homepage when logging out
  yield browserHistory.push('/');
}

function* setHostId(action) {
  if (action.hostedId) {
    yield put(setHostedId(action.hostedId));
  }
}

function* verifySSOToken({ token, partnerId }) {
  try {
    const {
      data: { token: accessToken },
    } = yield call(postSSOSignIn, token, partnerId);
    yield put(ssoVerifySuccess(accessToken, partnerId));
    const profileResponse = yield call(
      getPartnerProfile,
      partnerId,
      accessToken
    );
    yield put(getProfileSuccess(profileResponse.data));
    browserHistory.push('/');
  } catch (err) {
    yield put(ssoVerifyFailure(err));
  }
}

function* injectToken({ token, partnerId, expiresIn, tokenType }) {
  const profileResponse = yield call(
    getPartnerProfile,
    partnerId,
    `${tokenType} ${token}`
  );

  yield put(getProfileSuccess(profileResponse.data));
  yield put(loginSuccess(partnerId, token, '', expiresIn, tokenType));
  yield put(featureFlagsRequest(`${tokenType} ${token}`));
  yield call(handleURL);

  browserHistory.push('/');
}

// Same endpoint and logic is used for both :code and :id
// The difference is on error handling
function* handleGetLead({ id, code }) {
  try {
    const key = id || code;
    const [partnerId, token] = yield select((state) => [
      PartnerLoginApi.getPartnerId(state),
      PartnerLoginApi.getPartnerToken(state),
    ]);
    if (!partnerId || !token) return;

    const { data } = yield call(getLead, partnerId, key, token);
    yield put(getLeadSuccess(data));
    yield put(
      updateField('customerRelationship', CUSTOMER_RELATIONSHIP_REFERRAL)
    );
    yield put(
      updateReferralField(
        'customerId',
        data.leadReferral?.referredByCustomerAccountId
      )
    );
    yield put(validateForm(data.leadReferral?.referredByCustomerAccountId));
  } catch (err) {
    // Should save and send to the api, where later on there will be a process
    // of reconciliation as this lead actually might be invalid
    yield put(getLeadFailure(err, { id, code }));
  }
}

function* handleURL() {
  const query = new URLSearchParams(window.location.search);

  const leadId = query.get('lead_id');
  const leadCode = query.get('lead_code');
  if (leadId || leadCode) {
    yield call(handleGetLead, { id: leadId, code: leadCode });
  }
}

function* handlePostSupportingPartnerLogin() {
  const remoteSupportEnabled = yield select((state) =>
    FeatureFlagsApi.getFlag(state, FLAGS.REMOTE_SUPPORT)
  );
  if (!remoteSupportEnabled) return;

  yield put(getStartedPartner());
}

function* setDefaultDisplayName() {
  const remoteDisplayName = yield select(PartnerLoginApi.getRemoteDisplayName);

  if (!remoteDisplayName) {
    const profileDisplayName = yield select(PartnerLoginApi.getDisplayName);
    if (!isEmpty(profileDisplayName)) {
      yield put(setDisplayName(profileDisplayName.slice(0, 21)));
    }
  }
}

export function* combinedSagas() {
  yield takeLatest(GET_LEAD_REQUEST, handleGetLead);
  yield takeLatest(POST_PARTNER_LOGIN_REQUEST, handlePartnerLogin);
  yield takeLatest(PARTNER_LOGOUT, handlePartnerLogout);
  yield takeLatest(JTC_GET_STARTED, handleJTCGetStarted);
  yield takeLatest(
    POST_SUPPORTING_PARTNER_LOGIN_SUCCESS,
    handlePostSupportingPartnerLogin
  );
  yield takeLatest(
    POST_SUPPORTING_PARTNER_LOGIN_REQUEST,
    handleSupportingPartnerLogin
  );
  yield takeLatest(JTC_GET_STARTED, setHostId);
  yield takeLatest(SSO_VERIFY_REQUEST, verifySSOToken);
  yield takeLatest(PROXY_LOGIN, injectToken);
  yield takeLatest(
    landingPageTypes.SET_APPOINTMENT_TYPE,
    setDefaultDisplayName
  );
  yield handleURL();
}
