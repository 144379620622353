import {
  POST_PARTNER_LOGIN_REQUEST,
  POST_PARTNER_LOGIN_SUCCESS,
  POST_PARTNER_LOGIN_FAILURE,
  PARTNER_TOKEN_REFRESHED,
  PARTNER_LOGIN_UPDATE_FIELD,
  REFERRAL_FORM_VALIDATION,
  JTC_GET_STARTED,
  PARTNER_LOGOUT,
  GET_PARTNER_PROFILE_SUCCESS,
  PARTNER_LOGIN_CLEAR_LOADING,
  PARTNER_LOGIN_UPDATE_REFERRAL_FIELD,
  RESET_REFERRER_FIELDS,
  SET_SUPPORTING_ID_DIALOG,
  POST_SUPPORTING_PARTNER_LOGIN_REQUEST,
  POST_SUPPORTING_PARTNER_LOGIN_SUCCESS,
  POST_SUPPORTING_PARTNER_LOGIN_FAILURE,
  SET_HOSTED_ID,
  SET_SUPPORTING_ID,
  LANDING_HANDLE_ANALYTICS_PAGE,
  SSO_VERIFY_REQUEST,
  SSO_VERIFY_SUCCESS,
  SSO_VERIFY_FAILURE,
  PROXY_LOGIN,
  GET_LEAD_REQUEST,
  GET_LEAD_SUCCESS,
  GET_LEAD_FAILURE,
  SET_DISPLAY_NAME,
  SET_ENERGY_ONLY_ORGANIC,
} from './constants';
import validate from './validation';

export const logout = (preservedState = []) => ({
  type: PARTNER_LOGOUT,
  preservedState,
});
export const clearLoading = () => ({ type: PARTNER_LOGIN_CLEAR_LOADING });
export const loginFailure = (error) => ({
  type: POST_PARTNER_LOGIN_FAILURE,
  error,
});
export const updateField = (field, value) => ({
  type: PARTNER_LOGIN_UPDATE_FIELD,
  field,
  value,
});
export const updateReferralField = (field, value) => ({
  type: PARTNER_LOGIN_UPDATE_REFERRAL_FIELD,
  field,
  value,
});

export const resetReferrerFields = () => ({ type: RESET_REFERRER_FIELDS });

export const getStartedRemote = (remotePartnerId) => ({
  type: JTC_GET_STARTED,
  partnerLogin: false,
  remotePartnerId,
});

export const getStartedOrganic = (
  hostedId,
  energyOnly = false,
  leadCode,
  marketingParams
) => ({
  type: JTC_GET_STARTED,
  partnerLogin: false,
  hostedId,
  leadCode,
  energyOnly,
  marketingParams,
});

export const getStartedPartner = (hostedId) => ({
  type: JTC_GET_STARTED,
  partnerLogin: true,
  hostedId,
});

export const getProfileSuccess = (profile) => ({
  type: GET_PARTNER_PROFILE_SUCCESS,
  profile,
});

export const partnerTokenRefreshed = (token, refreshToken) => ({
  type: PARTNER_TOKEN_REFRESHED,
  token,
  refreshToken,
});
export const login = (partnerId, password, remember) => ({
  type: POST_PARTNER_LOGIN_REQUEST,
  partnerId,
  password,
  remember,
});

export const loginSuccess = (
  partnerId,
  token,
  refreshToken,
  expires,
  type
) => ({
  type: POST_PARTNER_LOGIN_SUCCESS,
  partnerId,
  token,
  refreshToken,
  expires,
  tokenType: type,
});

export const validateForm = (customerId) => {
  const validation = validate(customerId);

  return {
    type: REFERRAL_FORM_VALIDATION,
    ...validation,
  };
};

export const setSupportingIdDialog = (open) => ({
  type: SET_SUPPORTING_ID_DIALOG,
  open,
});

export const supportingIdLogin = (
  supportingPartnerId,
  password,
  partnerToSupport
) => ({
  type: POST_SUPPORTING_PARTNER_LOGIN_REQUEST,
  supportingPartnerId,
  password,
  partnerToSupport,
});

export const supportingIdLoginSuccess = (id, token, tokenType) => ({
  type: POST_SUPPORTING_PARTNER_LOGIN_SUCCESS,
  supportingPartnerId: id,
  accessToken: token,
  tokenType,
});
export const supportingIdLoginFailure = (error) => ({
  type: POST_SUPPORTING_PARTNER_LOGIN_FAILURE,
  error,
});

export const setHostedId = (hostedId) => ({
  type: SET_HOSTED_ID,
  hostedId,
});

export const setSupportingId = (id) => ({
  type: SET_SUPPORTING_ID,
  supportingPartnerId: id,
});

export const updateAnalyticsPage = () => ({
  type: LANDING_HANDLE_ANALYTICS_PAGE,
});

export const ssoVerifyRequest = (token, partnerId) => ({
  type: SSO_VERIFY_REQUEST,
  token,
  partnerId,
});

export const ssoVerifySuccess = (token, partnerId) => ({
  type: SSO_VERIFY_SUCCESS,
  token,
  partnerId,
});

export const ssoVerifyFailure = (error) => ({
  type: SSO_VERIFY_FAILURE,
  error,
});

export const proxyLogin = (token, partnerId, expiresIn, tokenType) => ({
  type: PROXY_LOGIN,
  token,
  partnerId,
  expiresIn,
  tokenType,
});

export const getLeadRequest = () => ({
  type: GET_LEAD_REQUEST,
});

export const getLeadSuccess = (lead) => ({
  type: GET_LEAD_SUCCESS,
  lead,
});

export const getLeadFailure = (error, lead) => ({
  type: GET_LEAD_FAILURE,
  lead,
  error,
});

export const setDisplayName = (name) => ({
  type: SET_DISPLAY_NAME,
  name,
});

export const setEnergyOnlyOrganic = (energyOnlyOrganic) => ({
  type: SET_ENERGY_ONLY_ORGANIC,
  energyOnlyOrganic,
});
